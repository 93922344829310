import './App.scss'
import React from "react"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"

import Home from "./pages/Home/Home"
import Merchants from "./pages/Merchants/Merchants"
import Merchant from "./pages/Merchant/Merchant"
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess"
import PaymentError from "./pages/PaymentError/PaymentError"
import TermsAndService from "./pages/TermsAndService/TermsAndService"
import ContactUs from "./pages/ContactUs/ContactUs"

const App = () => {
  return (
    <Router>
      <nav>
        <Link to="/"><div className='title'><img src={'/CustomerFirstCommerce.png'} height="50px" alt="logo"/></div></Link>

        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/merchants">Merchants</Link></li>
        </ul>
      </nav>

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/merchants" element={<Merchants />} />
          <Route path="/merchants/:id" element={<Merchant />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/error" element={<PaymentError />} />
          <Route path="/terms" element={<TermsAndService />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </main>

      <footer>
        <div>
          <Link to="/terms">Terms of Use</Link>
          <Link to="/contact">Contact Us</Link>
        </div>
      </footer>
    </Router>
  );
}

export default App
