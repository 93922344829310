import "./ContactUs.scss"
import React from "react";

const ContactUs = () => {
  return (
    <div className='ContactUs'>
      <h2>Contact Us</h2>

      <p>Contact us at: <a href='mailto:daphnes-corner@finixpayments.com'>daphnes-corner@finixpayments.com</a></p>
    </div>
  )
}

export default ContactUs
