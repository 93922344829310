import "./Merchants.scss"
import React, { useEffect, useState }  from "react"
import { Link } from "react-router-dom"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import filter from 'lodash/filter'
import Constants from 'Constants.js'

const approvedMerchantIds = [
  'MU7nUBgPUP3LfCHu3iVRiiPE', // Daphne's Corner Express
  'MUtyG674etiuWJvF4rK6QWWS', // Daphne's Corner
]

const fetchMerchants = async (setMerchants, setShowSpinner) => {
  try {
    const response = await fetch(`${Constants.FINIX_API_HOSTNAME}/merchants`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    const merchants = await response.json()
    const approvedMerchants = filter(merchants, ({ id, name }) => name && approvedMerchantIds.includes(id))

    setMerchants(approvedMerchants.reverse())
    setShowSpinner(false)
  } catch (error) {
    console.log("error", error);
    setShowSpinner(false)
  }
}

const Merchants = () => {
  const [merchants, setMerchants] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true)

  useEffect(() => {
    fetchMerchants(setMerchants, setShowSpinner)
  }, []);

  return (
    <div className="Merchants">
      { showSpinner && <LoadingSpinner /> }

      { !showSpinner && (
        <div className="merchant-list">
          { merchants.map(({ id, name, tags } ) => {
            return (
              <Link to={`/merchants/${id}`} key={id}>
                <div className="merchant">
                  <h3>{name || tags?.name}</h3>

                  <img src={tags?.logo} alt="merchant logo" />
                </div>
              </Link>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Merchants
