import "./Merchant.scss"
import React, { useEffect, useState }  from "react"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"
import GooglePayButtonC from '../GooglePayButton/GooglePayButtonC'
import { useParams } from "react-router-dom"
import numeral from 'numeral'
import ApplePay from '../ApplePay/ApplePay'
import Constants from 'Constants.js'

const moneyFormat = '$0,0.00'

const updateState = (state, setState, values) => {
  setState({
    ...state,
    ...values,
  })
}

const fetchMerchant = async (merchantId, state, setState) => {
  const response = await fetch(`${Constants.FINIX_API_HOSTNAME}/merchants/${merchantId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })

  const merchant = await response.json()

  updateState(state, setState, {
    merchant,
    showSpinner: false,
  })

  // initialize FinixAuth after fetching the merchant
  if (window.Finix) {
    console.log('setup FinixAuth after merchant fetch')
    window.FinixAuth = window.Finix.Auth('live', merchantId)
  }
}

const postPayment = async ({ data, merchant_id }) => {
  try {
    const response = await fetch(`${Constants.FINIX_API_HOSTNAME}/payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const {
      id,
      message,
    } = await response.json()

    if (id) {
      window.location.href = `/success?transfer_id=${id}&merchant_id=${merchant_id}`
    } else {
      if (message) {
        window.location.href = `/error?merchant_id=${merchant_id}&error=${message}`
      } else {
        window.location.href = `/error?merchant_id=${merchant_id}`
      }
    }
  } catch (error) {
    console.log("error", error);
    window.location.href = `/error?merchant_id=${merchant_id}`
  }
}

let PaymentForm

const submitPaymentForm = ({
  merchant_id,
  merchant_identity,
  amount,
  tags,
  recurringType,
  originalTransferId,
  reuseInstrumentId,
}) => {
  // Test ENV: "sandbox", "APeUfzF5qdNkBCtdgcf3333n"
  PaymentForm.submit("live", "AP9xuCgCd3bi2eLGzbPis5yW", function(err, res) {
    if (err) {
      console.log("There was an error tokenizing the payment card")
      window.location.href = `/error?merchant_id=${merchant_id}`
    } else {
      const token = res?.data?.id

      const sessionKey = window.FinixAuth.getSessionKey()

      const data = {
        token,
        amount,
        tags,
        merchant: merchant_id,
        merchant_identity,
        fraud_session_id: sessionKey,
        recurring_type: recurringType,
        original_transfer_id: originalTransferId,
        instrument_id: reuseInstrumentId
      }

      if (token) {
        postPayment({ data, merchant_id })
      } else {
        window.location.href = `/error?merchant_id=${merchant_id}`
      }
    }
  })
}

// Finix.js Tokenize Library
const initTokenizeForm = (setHasErrors) => {
  PaymentForm = window.PaymentForm.card((state, binInformation) => {
    if (state === undefined) {
      document.getElementById('brand').innerHTML = binInformation.cardBrand;
    }

    let hasErrors = state ? false : true

    state && Object.keys(state).forEach((fieldName) => {
      const { errorMessages = [] } = state[fieldName] || {}

      if (errorMessages.length > 0) {
        hasErrors = true
      }
    })

    setHasErrors(hasErrors)
  })

  function defineField(elementId, type, placeholder) {
    const field = PaymentForm.field(type, {
      placeholder,
      styles: {
        error: {
          color: "#d9534f",
        },
      }
    })

    document.getElementById(elementId).appendChild(field)
  }

  defineField("field-wrapper-number", "number", "Credit card number")
  defineField("field-wrapper-name", "name", "Name of card holder")
  defineField("field-wrapper-expiration_date", "expiration_date", "MM / YYYY")
  defineField("field-wrapper-security_code", "security_code", "CVC")
}

const Merchant = () => {
  const { id } = useParams()
  // const onDev = window.location.href.includes('dashboard-dev')
  // const identityId = onDev ? 'IDwhCCvPwCDEmiFd8Be7pDzN' : 'IDbQzjDGJQMZNA1DPGnpamW8'

  const canDisplayApplePay = window.ApplePaySession && window.ApplePaySession.canMakePayments('IDbQzjDGJQMZNA1DPGnpamW8')
  console.log('checks for ability to display apple pay button: ', canDisplayApplePay)

  const [hasErrors, setHasErrors] =  useState(true)

  const [state, setState] =  useState({
    merchant: {},
    productCount: 0,
    total: 0.00,
    showCheckout: false,
    formInitialized: false,
    showSpinner: true,
  })

  const [recurringType, setRecurringType] = useState('')

  const [originalTransferId, setOriginalTransferId] = useState('')

  const [reuseInstrumentId, setReuseInstrumentId] = useState('')

  const {
    merchant,
    productCount,
    total,
    showCheckout,
    formInitialized,
    showSpinner,
  } = state

  const {
    id: merchant_id,
    merchant_identity,
    name = '',
    tags = {},
  } = merchant

  const {
    product_name,
    product_cost,
    product_image,
    max_items,
  } = tags

  const paymentTags = {
    product_name,
    product_cost,
    product_count: productCount,
    total,
  }

  const checkoutDisabled = productCount <= 0
  const addMoreDisabled =  productCount >= max_items
  const formattedProductCost = numeral(product_cost).format(moneyFormat)
  const formattedTotal = numeral(total).format(moneyFormat)
  const purchaseDisabled = hasErrors

  const updateCount = (count) => {
    if (count >= 0) {
      updateState(state, setState, {
        productCount: count,
        total: parseFloat(product_cost) * count,
      })
    }
  }

  const toggleCheckout = () => {
    updateState(state, setState, {
      showCheckout: !showCheckout,
      formInitialized: false,
    })
  }

  if (showCheckout && !formInitialized) {
    setTimeout(() => {
      initTokenizeForm(setHasErrors)
    }, 200)

    updateState(state, setState, { formInitialized: true })
  }

  useEffect(() => {
    fetchMerchant(id, state, setState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const purchaseClicked = () => {
    updateState(state, setState, { showSpinner: true })

    submitPaymentForm({
      merchant_id,
      merchant_identity,
      amount: total,
      tags: paymentTags,
      recurringType,
      originalTransferId,
      reuseInstrumentId
    })
  }

  return (
    <div className="Merchant">
      { showSpinner && <LoadingSpinner /> }

      { !showCheckout && !showSpinner && (
        <>
          <div className='content'>
            <h1>{name}</h1>

            <div className='products'>
              <div className="product">
                <h3>{product_name}: {formattedProductCost}</h3>

                <img src={product_image} alt='product' />

                <div className="product-count">
                  <button onClick={() => { updateCount(productCount - 1) }} disabled={checkoutDisabled}>-</button>
                  <div className="count">{productCount}</div>
                  <button onClick={() => { updateCount(productCount + 1) }} disabled={addMoreDisabled}>+</button>
                </div>
                <div>
                  <input type="text" value={recurringType} onChange={e => setRecurringType(e.target.value) } placeholder="Optional Recurring Type" />
                </div>
                <div>
                  <input type="text" value={originalTransferId} onChange={e => setOriginalTransferId(e.target.value) } placeholder="Optional Original Recurring Transfer Id" />
                </div>
                <div>
                  <input type="text" value={reuseInstrumentId} onChange={e => setReuseInstrumentId(e.target.value) } placeholder="Optional Payment Instrument Id to use" />
                </div>
              </div>
            </div>
          </div>

          <div className='buttons'>
            <button className='checkout' onClick={toggleCheckout} disabled={checkoutDisabled}>
              <span>Checkout</span>
              <span>{formattedTotal}</span>
            </button>

            <GooglePayButtonC
              totalPrice={formattedTotal.replace('$','')}
              tags={paymentTags}
              merchant_identity={merchant_identity}
              merchant_id={merchant_id}
              recurringType={recurringType}
              originalTransferId={originalTransferId}
              reuseInstrumentId={reuseInstrumentId}
            />
          </div>
        </>
      )}

      { showCheckout && (
        <>
          <div className='content'>
            <h1>Checkout</h1>
            <h3>Buying {productCount} {product_name}{productCount > 1 ? 's' : ''}</h3>
            <h3>Total: {formattedTotal}</h3>
          </div>

          <form autoComplete="on">
            <div className="fake-field">
              <span id="field-wrapper-name" className="field-wrapper" />
            </div>

            <div className="fake-field">
              <span id="brand"></span>
              <span id="field-wrapper-number" className="field-wrapper" />
            </div>

            <div className="fake-field">
              <span id="field-wrapper-expiration_date" className="field-wrapper" />
              <span id="field-wrapper-security_code" className="field-wrapper" />
            </div>
          </form>

          <div className='buttons'>
            <button id="purchase" className="purchase" onClick={purchaseClicked} disabled={purchaseDisabled}>
              <span>Buy</span>
              <span>{formattedTotal}</span>
            </button>

            { canDisplayApplePay &&
              <ApplePay
                total={formattedTotal}
                merchant_id={merchant_id}
                merchant_identity={merchant_identity}
                recurringType={recurringType}
                originalTransferId={originalTransferId}
                reuseInstrumentId={reuseInstrumentId}
              />
            }

            <GooglePayButtonC
              totalPrice={formattedTotal.replace('$','')}
              tags={paymentTags}
              merchant_identity={merchant_identity}
              merchant_id={merchant_id}
              recurringType={recurringType}
              originalTransferId={originalTransferId}
              reuseInstrumentId={reuseInstrumentId}
            />

            <button className='back' onClick={toggleCheckout} disabled={checkoutDisabled}>Back</button>
          </div>
        </>
      )}
    </div>
  )
}

export default Merchant
