import "./TermsAndService.scss"
import React from "react"

const TermsAndService = () => {
  return (
    <>
        <div className='TermsAndService'>
            <h1>Terms of Use</h1>
            <p>
                Welcome to the Daphnes Corner website (“Website”), and/or the mobile applications (the “Apps”)(collectively, “Services”) of Finix, Inc. (a Delaware corporation).
                By using and accessing the Services, and the content and services contained therein, you agree to be bound by these terms and conditions (the “Terms“).
                If you do not agree to be bound by the Terms, you may not access or use the Services. Be sure to check this page periodically for updates, as your continued use of the Services signifies your acceptance of any changed items.
            </p>

            <h2>Access to Our Service</h2>
            <p>
                Access to this Service is permitted on a temporary basis, and we reserve the right to withdraw or amend the Services without notice.
                We will not be liable if for any reason this Website or app is unavailable at any time or for any period. From time to time, we may restrict access to some parts or all of this Website or app.
                This Website or app may contain links to other websites (the "Linked Sites"), which are not operated by www.daphnes-corner.com. <a href="www.daphnes-corner.com">www.daphnes-corner.com</a>&nbsp;
                has no control over the Linked Sites and accepts no responsibility for them or for any loss or damage that may arise from your use of them. Your use of the Linked Sites will be subject to the terms of use and service contained within each such site.
            </p>

            <h2>Intellectual Property, Software and Content</h2>
            <p>
                The intellectual property rights in all software and content (including photographic images) made available to you on or through this Website or app remains the property of www.daphnes-corner.com
                or its licensors and are protected by copyright laws and treaties around the world. All such rights are reserved by www.daphnes-corner.com and its licensors. You may store, print and display the
                content supplied solely for your own personal use. You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the content or copies of the content supplied
                to you or which appears on this Website or app nor may you use any such content in connection with any business or commercial enterprise.
            </p>

            <h2>Terms of Sale</h2>
            <p>
                By placing an order you are offering to purchase a product on and subject to the following terms and conditions.
                All orders are subject to availability and confirmation of the order price. <br />

                In order to contract with www.daphnes-corner.com you must be over 18 years of age and possess a valid credit or debit card issued by a bank acceptable to us.
                www.daphnes-corner.com retains the right to refuse any request made by you. This will usually be www.daphnes-corner.com or may in some cases be a third party.
                When placing an order you undertake that all details you provide to us are true and accurate, that you are an authorized user of the credit or debit card used
                to place your order and that there are sufficient funds to cover the cost of the goods. All prices advertised are subject to such changes.
            </p>
            <h3>(a) Pricing and Availability</h3>
            <p>Whilst we try and ensure that all details, descriptions and prices which appear on this Website or app are accurate, errors may occur. </p>
            <h3>(b) Payment </h3>
            <p>
                Upon receiving your order we carry out a standard authorization check on your payment card to ensure there are sufficient funds to fulfill the transaction.
                Your card will be debited upon authorisation being received. The monies received upon the debiting of your card shall be treated as a deposit against the value of the goods you wish to purchase.
            </p>

            <h2>Variation</h2>
            <p>www.daphnes-corner.com  shall have the right in its absolute discretion at any time and without notice to amend, remove or vary the Services and/or any page of this Website.</p>

            <h2>Proper Use of Our Services; Indemnity</h2>
            <p>
                You may not use the Services for any purpose that is unlawful or prohibited by these Terms, or cause damage on or through the Services.
                You promise that none of your communications with or through the Services will violate any applicable local, state, national or international law.
            </p>
            <p>
                You agree to defend, indemnify and hold Finix, its officers, directors, employees, agents, licensors, business associates, and suppliers harmless
                from and against any actual or threatened claims, actions or demands, liabilities and settlements including, without limitation, reasonable legal
                and accounting fees, resulting from, or alleged to result from, your use of the Services in a manner that violates or is alleged to violate these Terms or any applicable law.
            </p>

            <h2>Disclaimer of Warranty, Limitation of Liability, and Release</h2>
            <p>Finix does not guarantee the accuracy of information found on the Services. Your reliance on information found on the Services is at your own risk.</p>
            <p className="uppercase">
                THE SERVICES, AND EACH OF THE WEBSITE’S AND APPS’ CONTENTS, IS PROVIDED TO YOU “AS IS.” FINIX MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF THE RIGHTS OF THIRD PARTIES,
                DATA ACCURACY, OR QUIET ENJOYMENT.
            </p>
            <p className="uppercase">
                UNDER NO CIRCUMSTANCES WILL FINIX OR ANY OTHERS INVOLVED IN CREATING THE SERVICES AND ITS OR THEIR CONTENTS BE LIABLE FOR ANY DAMAGES OR INJURY, INCLUDING ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES RESULTING FROM ANY CIRCUMSTANCE INVOLVING THE SERVICES OR ITS OR THEIR CONTENT
                (INCLUDING BUT NOT LIMITED TO DAMAGES OR INJURY CAUSED BY ERROR, OMISSION, INTERRUPTION, DEFECT, FAILURE OF PERFORMANCE, MISDIRECTED OR REDIRECTED TRANSMISSIONS,
                FAILED INTERNET CONNECTIONS, UNAUTHORIZED USE OF THIS SERVICES, LOST DATA, DELAY IN OPERATION OR TRANSMISSION, BREACH OF SECURITY, LINE FAILURE, DEFAMATORY,
                OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER OF THIS SERVICES, OR COMPUTER VIRUS, WORM, TROJAN HORSE OR OTHER HARMFUL COMPONENT), EVEN IF YOU HAVE ADVISED FINIX  IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGE.
            </p>
            <p className="uppercase">
                FINIX DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE MATERIALS ON THE SERVICES IN TERMS OF THEIR TIMELINESS, CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE.
                WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE ACCURATE AND TIMELY INFORMATION ABOUT FINIX ON THE SERVICES, YOU SHOULD NOT ASSUME THAT THE INFORMATION PROVIDED IS ALWAYS UP TO DATE
                OR THAT THE SERVICES CONTAIN ALL THE RELEVANT INFORMATION AVAILABLE ABOUT OUR COMPANY. WE UNDERTAKE NO OBLIGATION TO VERIFY OR MAINTAIN THE CURRENCY OF SUCH INFORMATION.
            </p>
            <p>
                Applicable law may not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages.
                Accordingly, some of the above limitations or exclusions may not apply to you. However, in no event shall Finix aggregate liability to you or any third party for damages,
                losses, and causes of action exceed the amount paid by you, if any, for accessing the Services or $100, whichever is lesser. You agree to bring any and all actions within
                one year from the date of the accrual of the cause of action, and that actions brought after this date will be barred.
            </p>
            <p>
                In the event that you have a dispute with Finix, you release Finix (and our officers, directors, agents, and employees) from claims, demands, and damages
                (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way
                connected with such disputes. If you are a California resident, you waive California Civil Code section 1542 which says:
                “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release,
                which if known by him must have materially altered his settlement with the debtor.”
            </p>

            <h2>Violations and Additional Terms</h2>
            <p>
                Finix Inc. reserves the right to seek all remedies available at law and in equity for violations of these Terms. Please see our Privacy Notice for our policies regarding privacy, which is incorporated herein by reference.
            </p>

            <h2>No Waiver</h2>
            <p>
                No delay or failure by Finix to enforce any of these Terms shall constitute a waiver of any of our rights under these Terms.
                Neither the receipt of any funds by Finix nor the reliance of any person on our actions shall be deemed to constitute a waiver of any part of these Terms.
                Only a specific, written waiver signed by an authorized representative of Finix shall have any legal effect.
            </p>

            <h2>Severability</h2>
            <p>If any clause or provision set forth in these Terms is determined to be illegal, invalid or unenforceable under present or future law, the clause or provision shall be deemed to be deleted without affecting the enforceability of all remaining clauses or provisions.</p>

            <h2>Governing Law and Jurisdiction</h2>
            <p>
                These Terms and any disputes arising under or related to these Terms and/or the Privacy Notice to the Website or Apps will be governed by U.S. federal law and the laws of the State of Delaware,
                without reference to its conflict of law principles. Any such dispute shall be resolved exclusively in the State and Federal courts located in Kent County, Delaware.
                You agree to submit to the personal jurisdiction and venue of the courts of the State of Delaware for any legal proceeding involving the Website, the Apps and the services or content
                offered thereunder regardless of who initiated the proceeding.
            </p>

            <h2>Complaints</h2>
            <p>We operate a complaints handling procedure which we will use to try to resolve disputes when they first arise, please let us know if you have any complaints or comments.</p>

            <h2>For More Information</h2>
            <p>If you have any questions regarding our Terms, please contact us by sending an email to <a href="mailto: legal@finixpayments.com"> legal@finixpayments.com</a>or writing to us at:</p>
            <p className="bold italic">
                Finix, Inc.<br/>
                631 Howard Street<br/>
                San Francisco, CA 94105<br/>
                United States<br/>
            </p>

            <h2>Changes to this Agreement</h2>
            <p>
                Finix Inc. reserves the right, in its sole discretion, to modify, alter, or otherwise change these Terms at any time. We will provide notice of such change on the Website and/or Apps.
                Please review the Terms and/or Additional Terms periodically for changes. Your continued use of the Finix Inc. Services constitutes your acceptance of and agreement to be bound by these changes without limitation,
                qualification or change. If at any time you determine that you do not accept these changes, you must stop using the Services. <br />
                Terms of Use: February 9, 2022
            </p>
        </div>
        <br />
        <br />
        <div className='PrivacyNotice'>
            <h1>Privacy Notice</h1>
            <p className="bold">Last updated February 8, 2022</p>

            <p>Thank you for choosing to try our Daphne’s Corner app at Finix Inc. (“Company“, “we“, “us“, “our“).
                We are committed to protecting your Personal Information and your right to privacy.
                If you have any questions or concerns about this privacy notice (“Privacy Notice”, “Notice”), or our practices with regards to your Personal Information,
                please contact us at privacy@daphnes-corner.com. When you visit our website www.daphnes-corner.com (the “Website“), use our mobile applications,
                as the case may be (the “Apps“) and more generally, use any of our services (the “Services“, which include the Website and Apps),
                we appreciate that you are trusting us with your Personal Information. We take your privacy very seriously.
                In this Privacy Notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it.
                We hope you take some time to read through it carefully, as it is important. If there are any terms in this Privacy Notice that you do not agree with, please discontinue use of our Services immediately.
                Please note that for users located in the European Economic Area (“EEA”), the term Personal Information used in this Privacy Notice is equivalent to the term “Personal Information” under applicable European data protection laws.
                We use your Personal Information for providing and improving the
                Services. By using the Services, you agree to the collection and use of information in accordance with this Notice.
                Unless otherwise defined in this Privacy Notice, terms used in this ?

                This Privacy Notice applies to all information collected through our Services.
            </p>
            <p className="bold">Please read this Privacy Notice carefully as it will help you understand what we do with the information that we collect.</p>

            <br />
            <h2>Table of Contents</h2>
            <p className="underline upperCase">
                1. WHAT INFORMATION DO WE COLLECT?<br />
                2. HOW DO WE USE YOUR INFORMATION?<br />
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?<br />
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?<br />
                5. HOW LONG DO WE KEEP YOUR INFORMATION?<br />
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?<br />
                7. WHAT ARE YOUR PRIVACY RIGHTS?<br />
                8. DO YOU OFFER DO-NOT-TRACK FEATURES?<br />
                9. DO CALIFORNIA PRIVACY RIGHTS APPLY TO ME?<br />
                10. DO WE MAKE UPDATES TO THIS NOTICE?<br />
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?<br />
            </p>
            <br />

            <h3 className="upperCase">1. WHAT INFORMATION DO WE COLLECT?</h3>
            <p className="bold">Personal Information you disclose to us</p>
            <p>
                We DON'T collect your personal information such as login, email or password.
                There is no login needed to use Daphne’s Corner website or app.
            </p>
            <p className="bold">Information collected through our App</p>
            <p className="italic">In Short:  We collect information regarding your mobile device when you use our App.</p>
            <p>If you use our App, specifically we collect the following information:</p>
            <ul>
                <li>
                    Mobile Device Data. We automatically collect device information (such as your mobile device ID, model and manufacturer),
                    operating system, version information and system configuration information, device and application identification numbers,
                    browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server).
                    If you are using our App, we may also collect information about your mobile device’s operating system or platform, the type of mobile device you use,
                    your mobile device’s unique device ID and information about the features of our App you accessed.
                </li>
            </ul>
            <p>This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</p>
            <br />

            <h3 className="upperCase">2. HOW DO WE USE YOUR INFORMATION?</h3>
            <p className="italic">In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
            <p>
                We use Personal Information collected via our Services for a variety of business purposes described below.
                We process your Personal Information for these purposes in reliance on our legitimate business interests,
                in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
                We indicate the specific processing grounds we rely on next to each purpose listed below.
            </p>
            <p>We use the information we collect or receive:</p>
            <ul>
                <li>To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).</li>
                <li>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</li>
                <li> To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
                <li>
                    For other business purposes. We may use your information for other business purposes, such as data analysis, identifying usage trends,
                    determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience.
                    We will use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include Personal Information.
                    We will not use identifiable Personal Information, at any time, for any purpose, without your consent.
                </li>
            </ul>
            <br />

            <h3 className="upperCase">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
            <p className="italic">In Short:  We only share information to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
            <p>We may process or share your data that we hold based on the following legal basis:</p>
            <ul>
                <li>
                    Vital Interests: We may disclose device information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies,
                    suspected fraud, situations involving potential threats to the safety of any illegal activities, or as evidence in litigation in which we are involved.
                </li>
            </ul>
            <p>In addition to the foregoing, we may need to process data or share your in the following situations:</p>
            <ul>
                <li>Business Transfers. We may share or transfer information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            </ul>
            <br />

            <h3 className="upperCase">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
            <p className="italic">In Short:  We do not use cookies and other tracking technologies to collect and store your information.</p>
            <br />


            <h3 className="upperCase">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
            <p className="italic">In Short:  We keep app and device information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.</p>
            <br />

            <h3 className="upperCase">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
            <p className="italic">
                In Short:  We aim to protect app and device Information through a system of organizational and technical security measures.
                We have implemented appropriate technical and organizational security measures designed to protect the security of any Information we process.
            </p>
            <br />

            <h3 className="upperCase">7. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
            <p className="italic bold">In Short:  You may refuse to use our app or website at any time.</p>
            <p className="italic">
                If you are a resident in the European Economic Area and you believe we are unlawfully processing your Personal Information,
                you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
                <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"> http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html</a>
            </p>
            <p className="italic">
                If you are a resident in Switzerland, the contact details for the data protection authorities are available here:
                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html."> https://www.edoeb.admin.ch/edoeb/en/home.html.</a>
            </p>
            <p className="italic">
                If you have questions or comments about your privacy rights, you may email us at:
                <a href="mailto: privacy@dapnes-corner.com"> privacy@dapnes-corner.com</a>
            </p>
            <br />

            <h3 className="upperCase">8. DO YOU OFFER DO-NOT-TRACK FEATURES?</h3>
            <p className="italic">
                In short: No, we do not respond to Do Not Track requests.
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate
                to signal your privacy preference not to have data about your online browsing activities monitored and collected. California and Delaware law
                require us to indicate whether we honor “Do Not Track” settings in your browser concerning targeted advertising. At this stage no uniform technology
                standard for recognizing and implementing DNT signals has been finalized. Accordingly, we adhere to the standards set out in this Privacy Notice and do not
                monitor or respond to Do Not Track browser requests or any other mechanism that automatically communicates your choice not to be tracked online.
                If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
            </p>
            <br />

            <h3 className="upperCase">9. DO CALIFORNIA PRIVACY RIGHTS APPLY TO ME?</h3>
            <div className="italic">
                <p>In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your Personal Information.</p>
                <p className="bold">
                    This section is only applicable to California residents for purposes of compliance with the California Consumer Privacy Act of 2018 (“CCPA”).
                    Defined terms used in this section, including but not limited to “Business Purpose”, “Consumers,” “Personal Information” and “Sale” (or “Sell”) are used as such terms are defined by and interpreted pursuant to the CCPA.
                </p>
                <p>The California Code of Regulations defines a “resident” as:</p>
                <ol>
                    <li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
                    <li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
                </ol>
                <p>All other individuals are defined as “non-residents.”</p>
                <p>If this definition of “resident” applies to you, we must adhere to certain rights and obligations regarding your Personal Information.</p>
                <p>What categories of Personal Information do we collect?</p>
                <p>We have collected the following categories of Personal Information in the past twelve (12) months:</p>
                <table>
                    <tr>
                        <th>Category</th>
                        <th>Examples</th>
                        <th>Collected</th>
                    </tr>
                    <tr>
                        <td>A. App usage data</td>
                        <td>Device type, operating system</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>B. Geolocation data</td>
                        <td>Device location</td>
                        <td>YES</td>
                    </tr>
                </table>
                <p className="bold">How do we use and share your Personal Information?</p>
                <p>
                    More information about our data collection and sharing practices can be found in this Privacy Notice.<br />
                    Additionally, you may contact us by email at privacy@daphnes-corner.com, or by referring to the contact details at the bottom of this document.<br />
                    If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.<br />
                    Will your information be shared with anyone else?<br />
                    We may use your Personal Information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be “selling” your personal data. <br />
                    Finix Inc. has not disclosed or sold any Personal Information to third parties for a business or commercial purpose in the preceding 12 months. Finix Inc. will not sell Personal Information in the future belonging to website visitors, app users and other consumers.
                </p>
                <p className="bold">Your rights with respect to your personal data</p>
                <p className="underline">Right to be informed – Request to know</p>
                <p>Depending on the circumstances, you have a right to know:</p>
                <ul>
                    <li>whether we collect and use your Personal Information;</li>
                    <li>the categories of Personal Information that we collect;</li>
                    <li>the purposes for which the collected Personal Information is used;</li>
                    <li>whether we sell your Personal Information to third parties;</li>
                    <li>the categories of Personal Information that we sold or disclosed for a business purpose;</li>
                    <li>the categories of third parties to whom the Personal Information was sold or disclosed for a business purpose; and</li>
                    <li>the business or commercial purpose for collecting or selling Personal Information.</li>
                </ul>
                <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>
                <p className="underline">Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
                <p>We will not discriminate against you if you exercise your privacy rights.</p>
            </div>
            <br />

            <h3 className="upperCase">10. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
            <p className="italic">
                In Short:  Yes, we will update this Notice as necessary to stay compliant with relevant laws.
                We may update this Privacy Notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible.
                If we make material changes to this Privacy Notice, we may notify you by prominently posting a notice of such changes. We encourage you to review this Privacy Notice frequently to be informed
                of how we are protecting your information.  Your continued use of the Website or app after we post any modifications to the Privacy Notice on this page will constitute your acknowledgment of
                the modifications and your consent to abide and be bound by the modified Privacy Notice.
            </p>
            <br />

            <h3 className="upperCase">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
            <p className="italic">
                If you have questions or comments about this Notice, you may email us at
                <a href="mailto: privacy@daphnes-corner.com"> privacy@daphnes-corner.com</a> or by mail to:
            </p>
            <p className="italic bold">
                Finix, Inc.<br/>
                631 Howard Street<br/>
                San Francisco, CA 94105<br/>
                United States<br/>
            </p>
        </div>
    </>
  )
}

export default TermsAndService
