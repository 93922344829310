import "./HexagonSVG.scss"
import React from "react"

const HexagonSVG = ({
  className = 'hex-1',
  width = "310",
  height = "228",
  viewBox = "0 0 310 228",
  fill = "none",
}) => {
  return (
    <svg className={`HexagonSVG ${className}`} width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path opacity="0.346"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M154.952 2L2.31458 76.8328L154.952 151.666L307.591 76.8328L154.952 2Z"
          stroke="white"
          strokeWidth="3"
          strokeLinejoin="round">
        </path>

        <path opacity="0.346"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.036 151.287V226.293L308.072 151.287V77.1538L155.036 151.287Z"
          stroke="white"
          strokeWidth="3"
          strokeLinejoin="round">
        </path>

        <path opacity="0.346"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M154.952 76.7393L2.31458 151.572L154.952 226.405L307.591 151.572L154.952 76.7393Z"
          stroke="white"
          strokeWidth="3"
          strokeLinejoin="round">
        </path>

        <path opacity="0.346"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.036 77.1541V2.14819L2 77.1541V151.288L155.036 77.1541Z"
          stroke="white"
          strokeWidth="3"
          strokeLinejoin="round">
        </path>
      </g>
    </svg>
  )
}

export default HexagonSVG
