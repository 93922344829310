import React from 'react'
import './ApplePayS.scss'
import Constants from 'Constants.js'

const ApplePay = ({
  total,
  merchant_id,
  merchant_identity,
  recurringType,      
  originalTransferId, 
  reuseInstrumentId
}) => {
  const processPayment = async ({ session, token }) => {
    const sessionKey = window.FinixAuth.getSessionKey()

    const request = {
      type: 'APPLE_PAY',
      third_party_token: token,
      amount: total.substring(1),
      merchant: merchant_id,
      merchant_identity,
      fraud_session_id: sessionKey,
      recurring_type : recurringType,
      original_transfer_id : originalTransferId,
      reuse_instrument_id: reuseInstrumentId
    }

    try {
      const response = await fetch(`${Constants.FINIX_API_HOSTNAME}/payment/APPLE_PAY`,
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(request),
        })

      const {
        id,
        message,
      } = await response.json()

      if (id) {
        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        window.location.href = `/success?transfer_id=${id}`
      } else {
        session.completePayment(window.ApplePaySession.STATUS_FAILURE)

        if (message) {
          window.location.href = `/error?error=${message}`
        } else {
          window.location.href = `/error`
        }
      }

    } catch (error) {
      console.log("error", error);
      window.location.href = `/error`
    }
  }

  const handleValidateApplePaySession = async (session) => {
    session.onvalidatemerchant = async (event) => {
      const { validationURL }  = event

      const request = {
        validation_url: validationURL,
        merchant_identity: 'IDbQzjDGJQMZNA1DPGnpamW8',
        domain: 'daphnes-corner.com',
        display_name: 'Daphne\'s Corner Site',
      }

      const response = await fetch(`${Constants.FINIX_API_HOSTNAME}/apple_pay_sessions`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(request),
      })

      const { session_details: sessionDetails } = await response.json()

      const normalizedSessionDetails = JSON.parse(sessionDetails)

      session.completeMerchantValidation(normalizedSessionDetails)
    }
  }

  const handleOnPaymentAuthorized = (session) => {
    session.onpaymentauthorized = (event) => {
      const { payment: paymentToken } = event
      const token = JSON.stringify(paymentToken)

      processPayment({ session, token })
    }
  }

  const handleValidateSessionAndPaymentAuthorization = async (session) => {
    await handleValidateApplePaySession(session)

    handleOnPaymentAuthorized(session)
  }

  const startApplePaySession = async () => {
    const request = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'Daphne\'s Corner', amount: total.substring(1) },
    }

    console.log('startapplepaysession')

    const session = new window.ApplePaySession(3, request)

    session.begin()

    handleValidateSessionAndPaymentAuthorization(session)
  }

  return (
    <div className='apple-pay-button' onClick={startApplePaySession} />
  )
}

export default ApplePay
