import React, { Component } from 'react'
import createGooglePayPaymentRequest from './createGooglePayPaymentRequest'
import GooglePayButton from './GooglePayButton'
import Constants from 'Constants.js'

const postPayment = async ({
  token,
  total,
  merchant_identity,
  tags,
  merchant_id,
  paymentDescription,
  recurringType,      
  originalTransferId, 
  reuseInstrumentId
}) => {
  const sessionKey = window.FinixAuth.getSessionKey()

  const data = {
    type: "GOOGLE_PAY",
    third_party_token: token,
    amount: total,
    merchant: merchant_id,
    merchant_identity,
    tags,
    fraud_session_id: sessionKey,
    recurring_type : recurringType,
    original_transfer_id : originalTransferId,
    reuse_instrument_id: reuseInstrumentId
  }

  try {
    const response = await fetch(`${Constants.FINIX_API_HOSTNAME}/payment/GOOGLE_PAY`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    const {
      id,
      message,
    } = await response.json()

    const description = `Google Pay ${paymentDescription}`

    if (id) {
      window.location.href = `/success?transfer_id=${id}&merchant_id=${merchant_id}&description=${description}`
    } else {
      if (message) {
        window.location.href = `/error?merchant_id=${merchant_id}&error=${message}`
      } else {
        window.location.href = `/error?merchant_id=${merchant_id}`
      }
    }
  } catch (error) {
    console.log("error", error);
    window.location.href = `/error?merchant_id=${merchant_id}`
  }
}

class GooglePayButtonC extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gPayButton: undefined,
    }
  }

  onProcessPayment = () => {
    const {
      totalPrice = "1.00",
      merchant_identity,
      merchant_id,
      tags,
      recurringType,      
      originalTransferId, 
      reuseInstrumentId
    } = this.props

    const paymentRequest = createGooglePayPaymentRequest({ totalPrice, merchantId: 'BCR2DN6TZ75NJNTH', gatewayMerchantId: merchant_identity })

    window.googlePayClient.loadPaymentData(paymentRequest).then((result) => {
      const token = result?.paymentMethodData?.tokenizationData?.token
      const paymentDescription = result?.paymentMethodData?.description

      if (token) {
        postPayment({
          token,
          total: totalPrice,
          merchant_identity,
          tags,
          merchant_id,
          paymentDescription,
          recurringType,      
          originalTransferId, 
          reuseInstrumentId
        })
      }
    })
  }

  createAndAddGooglePayButton = () => {
    const googlePayButton = window.googlePayClient.createButton({
      buttonColor: "default",
      buttonType: "buy",
      onClick: () => this.onProcessPayment(),
    })

    this.setState({ gPayBtn: googlePayButton })
  }

  render() {
    const { gPayBtn } = this.state

    return (
      <GooglePayButton
        {...this.props}
        createAndAddGooglePayButton={this.createAndAddGooglePayButton}
        onProcessPayment={this.onProcessPayment}
        gPayBtn={gPayBtn}
      />
    )
  }
}

export default GooglePayButtonC
