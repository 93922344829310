const createGooglePayPaymentRequest = ({
  totalPrice = '0',
  gatewayMerchantId = '',
  merchantId = '',
}) => {
  return ( {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            'gateway': 'finix',
            gatewayMerchantId,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: merchantId,
      merchantName: 'Finix Payments, Inc.',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice,
      currencyCode: 'USD',
      countryCode: 'US',
    },
    shippingAddressRequired: true,
    shippingAddressParameters: {
      phoneNumberRequired: true,
    }
  })
}

export default createGooglePayPaymentRequest
