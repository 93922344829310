import "./Home.scss"
import React from "react"
import HexagonSVG from "../HexagonSVG/HexagonSVG"
import { Link } from "react-router-dom"

const Home = () => {
  return (
    <div className="Home">
      <div className="intro">
        <div className="container">
          <h1>Welcome to Customer First Commerce</h1>

          <p>Customer First Commerce is a software platform that provides end to end commerce services to small and medium businesses, including hosting, inventory management and payments</p>

          <HexagonSVG className='hex-1' />
          <HexagonSVG className='hex-2' />
        </div>
      </div>

      <div className='cols'>
        <div className='col'>
          <h3>Merchants Directory</h3>
          <p>Browse our partnered merchants using our Merchant Directory page to quickly find what you are looking to buy.</p>

          <Link to='/merchants'>
            <button className='view-merchants'>View Merchants</button>
          </Link>
        </div>

        {/*<div className='col'>*/}
          {/*<h3>Label 2</h3>*/}
          {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>*/}
        {/*</div>*/}

        {/*<div className='col'>*/}
          {/*<h3>Label 3</h3>*/}
          {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>*/}
        {/*</div>*/}
      </div>

      {/*<div className="outro">*/}
        {/*<div className="container">*/}
          {/*<h1>Lorem Ipsum</h1>*/}
          {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}

          {/*<h1>Lorem Ipsum</h1>*/}
          {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}

          {/*<h1>Lorem Ipsum</h1>*/}
          {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
        {/*</div>*/}
      {/*</div>*/}
    </div>
  )
}

export default Home
