import "./LoadingSpinner.scss"
import React from "react"

const LoadingSpinner = () => {
  return (
    <div className='LoadingSpinner'>
      <div className='spinner'>
        <div className='block'>
          <div className='item' />
          <div className='item' />
          <div className='item' />
          <div className='item' />
          <div className='item' />
          <div className='item' />
          <div className='item' />
          <div className='item' />
        </div>
      </div>
    </div>
  )
}

export default LoadingSpinner
