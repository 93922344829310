import React from 'react'

const GooglePayButton = ({
  gPayBtn,
  createAndAddGooglePayButton = () => {},
  onProcessPayment = () => {},
}) => {
  if(!gPayBtn) {
    createAndAddGooglePayButton()
  }

  return (
    <div className='GooglePayButton' onClick={()=>onProcessPayment()} dangerouslySetInnerHTML={{ __html: gPayBtn && gPayBtn.innerHTML }}/>
  )
}

export default GooglePayButton
