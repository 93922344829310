import "./PaymentError.scss"
import React from "react"
import { Link } from "react-router-dom"

const PaymentError = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const merchantId = params.merchant_id
  const error = params.error

  return (
    <div className="PaymentError">
      <div className="error">
        <h2>Payment Error</h2>

        { !error && <p>There was an error while trying to process your payment.</p> }

        { error && <p>{error}</p> }
      </div>

      <div className='buttons'>
        <Link to={`/merchants/${merchantId}`}><button className="back">Back</button></Link>
        <Link to={'/merchants'}><button className='home'>Merchants</button></Link>
      </div>
    </div>
  )
}

export default PaymentError
