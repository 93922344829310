import "./PaymentSuccessS.scss"
import React from "react"
import { Link } from "react-router-dom"

const PaymentSucess = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  const transferId = params.transfer_id
  const merchantId = params.merchant_id
  const description = params.description

  return (
    <div className="PaymentSuccess">
      <div className='success'>
        <h2>Payment Successfully Processed</h2>

        { description && <p>Your order has been successfully processed using <span className='description'>{description}</span>. Please show your receipt to the vendor to pick up your order.</p> }
        { !description && <p>Your order has been successfully processed. Please show your receipt to the vendor to pick up your order.</p>}

        <h4>Receipt: {transferId}</h4>
      </div>

      <div className='buttons'>
        <Link to={`/merchants/${merchantId}`}><button className="back">Back</button></Link>
        <Link to={'/merchants'}><button className='home'>Merchants</button></Link>
      </div>
    </div>
  )
}

export default PaymentSucess
